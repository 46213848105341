import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';

import Token from './Token.component';

const tokenColorsDictionary = {
  none: '#e0e0d1',
  blue: '#5277ff',
  violet: '#8e52ff',
  green: '#38f000',
  yellow: '#ffeb52',
  red: '#ff2a12',
};

const tokenRanksDictionary = {
  success: 'V',
  failure: 'X',
  entropy: '0',
};

const tokenColors = Object.entries(tokenColorsDictionary).map(
  ([_, color]) => color,
);

const tokensDefinition = Object.entries(tokenRanksDictionary)
  .map(([_, rank]) => rank)
  .reduce((acc, rank) => {
    for (const color of tokenColors) {
      acc = [
        ...acc,
        {
          id: uuid.v4(),
          rank,
          color,
        },
      ];
    }

    return acc;
  }, []);

const typicalTokensSchema = [...Array(5)].map(() => ({
  rank: tokenRanksDictionary.failure,
  color: tokenColorsDictionary.none,
}));

const hardTokensSchema = [...Array(8)].map(() => ({
  rank: tokenRanksDictionary.failure,
  color: tokenColorsDictionary.none,
}));

const extremeTokensSchema = [...Array(11)].map(() => ({
  rank: tokenRanksDictionary.failure,
  color: tokenColorsDictionary.none,
}));
const heroicTokensSchema = [...Array(15)].map(() => ({
  rank: tokenRanksDictionary.failure,
  color: tokenColorsDictionary.none,
}));

const BucketWidget = ({ addTokenToPool, addTokenCollectionToPool }) => (
  <>
    {tokensDefinition.map(({ id, rank, color }) => (
      <Token
        key={id}
        id={id}
        rank={rank}
        color={color}
        tokenClickHandler={addTokenToPool}
      />
    ))}
    <button
      type="button"
      className="nes-btn is-success col-md-5 token-collection-button"
      onClick={() => addTokenCollectionToPool(typicalTokensSchema)}
    >
      Typical
    </button>
    <button
      type="button"
      className="nes-btn is-primary col-md-5 token-collection-button"
      onClick={() => addTokenCollectionToPool(hardTokensSchema)}
    >
      Hard
    </button>
    <button
      type="button"
      className="nes-btn is-warning col-md-5 token-collection-button"
      onClick={() => addTokenCollectionToPool(extremeTokensSchema)}
    >
      Extreme
    </button>
    <button
      type="button"
      className="nes-btn is-error col-md-5 token-collection-button"
      onClick={() => addTokenCollectionToPool(heroicTokensSchema)}
    >
      Heroic
    </button>
  </>
);

BucketWidget.propTypes = {
  addTokenToPool: PropTypes.func.isRequired,
  addTokenCollectionToPool: PropTypes.func.isRequired,
};

export default BucketWidget;
