import React from 'react';
import PropTypes from 'prop-types';

import Token from './Token.component';

const PoolWidget = ({ pool, removeTokenFromPool }) => (
  <>
    {pool.length
      ? pool.map(({ id, rank, color }) => (
          <Token
            key={id}
            id={id}
            rank={rank}
            color={color}
            tokenClickHandler={() => removeTokenFromPool({ id })}
          />
        ))
      : null}
  </>
);

PoolWidget.propTypes = {
  pool: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      rank: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  removeTokenFromPool: PropTypes.func.isRequired,
};

export default PoolWidget;
