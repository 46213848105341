import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import '../../App.css';

import LobbyPageComponent from './LobbyPage.component';
import CreateSessionModal from '../modals/CreateSessionModal.container';
import JoinSessionModal from '../modals/JoinSessionModal.container';

const LobbyPage = ({ userId }) => {
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);
  const [showJoinSessionModal, setShowJoinSessionModal] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [sessionList, setSessionList] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!userId) {
      return setIsFetchingData(false);
    }

    (async () => {
      setIsFetchingData(true);
      const {
        data: { sessionId },
      } = await axios.get(`/api/sessions/get-current-user-session/${userId}`);

      if (sessionId) {
        setSessionId(sessionId);
      }

      setIsFetchingData(false);
    })();
  }, [userId]);

  const closeCreateSessionModal = () => setShowCreateSessionModal(false);

  const closeJoinSessionModal = () => setShowJoinSessionModal(false);

  const submitCreateSession = async ({ sessionName, password }) => {
    const {
      data: { sessionId },
    } = await axios.post('/api/sessions/create-session', {
      sessionName,
      password,
    });

    return history.push(`/session/${sessionId}`);
  };

  const joinSession = async (session) => {
    history.push(`/session/${session.sessionId || sessionId}`);
  };

  const openCreateSessionModal = () => {
    setShowCreateSessionModal(true);
  };

  const getSessionList = async () => {
    try {
      const {
        data: { sessionList },
      } = await axios.get('/api/sessions');

      setSessionList(sessionList);
      setShowJoinSessionModal(true);
    } catch (error) {
      console.error(error);
      setShowJoinSessionModal(false);
    }
  };

  const logoutFromSession = async () => {
    await axios.delete(
      `/api/sessions/${sessionId}/logout-user-from-session/${userId}`,
    );

    setSessionId(null);
  };

  return (
    <>
      <CreateSessionModal
        showCreateSessionModal={showCreateSessionModal}
        closeCreateSessionModal={closeCreateSessionModal}
        submitCreateSession={submitCreateSession}
      />
      <JoinSessionModal
        sessionList={sessionList}
        showJoinSessionModal={showJoinSessionModal}
        closeJoinSessionModal={closeJoinSessionModal}
        submitJoinSession={joinSession}
      />
      <LobbyPageComponent
        openCreateSessionModal={openCreateSessionModal}
        getSessionList={getSessionList}
        joinBackToSession={joinSession}
        logoutFromSession={logoutFromSession}
        sessionId={sessionId}
        isFetchingData={isFetchingData}
      />
    </>
  );
};

LobbyPage.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default LobbyPage;
