import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const PasswordMinLength = 8;
const SessionNameMinLength = 4;

const CreateSessionModal = ({
  showCreateSessionModal,
  closeCreateSessionModal,
  submitCreateSession,
}) => {
  const [sessionName, setSessionName] = useState('');
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);
  const modal = useRef(null);

  // fields validation
  useEffect(() => {
    if (sessionName && isPasswordRequired) {
      if (
        password.length >= PasswordMinLength &&
        confirmPassword.length >= PasswordMinLength &&
        password === confirmPassword
      ) {
        return setIsFormValid(true);
      }
    }

    if (sessionName && !isPasswordRequired) {
      if (sessionName.length >= SessionNameMinLength) {
        return setIsFormValid(true);
      }
    }

    return setIsFormValid(false);
  }, [
    setIsFormValid,
    confirmPassword,
    sessionName,
    isPasswordRequired,
    password,
  ]);

  const resetForm = useCallback(() => {
    setSessionName('');
    setIsPasswordRequired(false);
    setPassword('');
    setConfirmPassword('');
  }, [setSessionName, setIsPasswordRequired, setPassword, setConfirmPassword]);

  const closeModal = useCallback(() => {
    resetForm();
    closeCreateSessionModal();
  }, [resetForm, closeCreateSessionModal]);

  const handleSubmit = useCallback(() => {
    if (!isFormValid) {
      return;
    }

    const payload = {
      sessionName,
      password: password.length ? password : null,
    };

    resetForm();
    closeCreateSessionModal();

    submitCreateSession(payload);
  }, [
    isFormValid,
    sessionName,
    password,
    resetForm,
    closeCreateSessionModal,
    submitCreateSession,
  ]);

  const passwordFieldDisabled = !isPasswordRequired;
  const confirmPasswordFieldDisabled = !isPasswordRequired || !password;

  const passwordFieldClassName = passwordFieldDisabled
    ? 'nes-field riggeddice-dialog-body password-field-disabled'
    : 'nes-field riggeddice-dialog-body';

  const confirmPasswordFieldClassName = confirmPasswordFieldDisabled
    ? 'nes-field riggeddice-dialog-body password-field-disabled'
    : 'nes-field riggeddice-dialog-body';

  return (
    <Modal show={showCreateSessionModal}>
      <div className="nes-dialog is-rounded riggeddice-dialog" ref={modal}>
        Create a new session
        <menu className="dialog-menu">
          <div className="nes-field riggeddice-dialog-body">
            <label>Session Name</label>
            <input
              type="text"
              onChange={({ target }) => setSessionName(target.value)}
              value={sessionName}
              className="nes-input"
            />
          </div>
          <label>
            <input
              type="checkbox"
              className="nes-checkbox"
              checked={isPasswordRequired}
              onChange={() => setIsPasswordRequired(!isPasswordRequired)}
            />
            <span>Password required</span>
          </label>
          <div className={passwordFieldClassName}>
            <label>Password</label>
            <input
              type="password"
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              className="nes-input"
              disabled={passwordFieldDisabled}
              autoComplete="on"
            />
          </div>
          <div className={confirmPasswordFieldClassName}>
            <label>Confirm password</label>
            <input
              type="password"
              onChange={({ target }) => setConfirmPassword(target.value)}
              value={confirmPassword}
              className="nes-input"
              disabled={confirmPasswordFieldDisabled}
              autoComplete="on"
            />
          </div>
          <button className="nes-btn is-error btn-left" onClick={closeModal}>
            Cancel
          </button>
          <button
            className={
              isFormValid
                ? 'nes-btn is-success btn-right'
                : 'nes-btn is-disabled btn-right'
            }
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Create
          </button>
        </menu>
      </div>
    </Modal>
  );
};

CreateSessionModal.propTypes = {
  showCreateSessionModal: PropTypes.bool.isRequired,
  closeCreateSessionModal: PropTypes.func.isRequired,
  submitCreateSession: PropTypes.func.isRequired,
};

export default CreateSessionModal;
