import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Image } from 'react-bootstrap';

import '../../App.css';

const LobbyPageComponent = ({
  openCreateSessionModal,
  getSessionList,
  joinBackToSession,
  logoutFromSession,
  isFetchingData,
  sessionId,
}) => {
  const [showLoader, setShowLoader] = useState(true);
  const [showLoggedToSessionView, setShowLoggedToSessionView] = useState(false);
  const [showDefaultSessionView, setShowDefaultSessionView] = useState(false);

  useEffect(() => {
    if (!sessionId && !isFetchingData) {
      setTimeout(() => {
        setShowDefaultSessionView(true);
        setShowLoggedToSessionView(false);
        return setShowLoader(false);
      }, 600);
    }

    if (sessionId && !isFetchingData) {
      setTimeout(() => {
        setShowLoggedToSessionView(true);
        setShowDefaultSessionView(false);
        return setShowLoader(false);
      }, 600);
    }
  }, [
    setShowLoader,
    setShowLoggedToSessionView,
    setShowDefaultSessionView,
    isFetchingData,
    sessionId,
  ]);

  const Loader = <div className="loader">Loading Data...</div>;

  const LoggedToSessionView = (
    <>
      <Col className="nes-container is-rounded is-dark">
        <div className="title">
          <p>Join back to the previous session</p>
        </div>
        <button
          type="button"
          className="nes-btn is-success"
          onClick={joinBackToSession}
        >
          Log Back
        </button>
      </Col>
      <Col className="nes-container is-rounded is-dark">
        <div className="title">
          <p>Logout from the previous session</p>
        </div>
        <button
          type="button"
          className="nes-btn is-warning"
          onClick={logoutFromSession}
        >
          Logout
        </button>
      </Col>
    </>
  );

  const DefaultSessionView = (
    <>
      <Col className="nes-container is-rounded is-dark">
        <div className="title">
          <p>Create a new Riggeddice session</p>
        </div>
        <button
          type="button"
          className="nes-btn is-warning"
          onClick={openCreateSessionModal}
        >
          Create
        </button>
      </Col>
      <Col className="nes-container is-rounded is-dark">
        <div className="title">
          <p>Join to existing Riggeddice session</p>
        </div>
        <button
          type="button"
          className="nes-btn is-warning"
          onClick={getSessionList}
        >
          Join
        </button>
      </Col>
    </>
  );

  const Footer = () => {
    if (showLoader) {
      return Loader;
    }

    if (showLoggedToSessionView) {
      return LoggedToSessionView;
    }

    if (showDefaultSessionView) {
      return DefaultSessionView;
    }
  };

  return (
    <Container className="riggeddice-container">
      <Row className="nes-container is-dark with-title is-centered justify-content-md-center">
        <div className="title">
          <h1 className="nes-text is-warning">WELCOME TO RIGGEDDICE</h1>
        </div>
        <Row className="nes-container is-dark is-rounded is-centered justify-content-md-center">
          <Row className="nes-container is-dark is-rounded is-centered justify-content-md-center">
            <Image
              className="riggeddice-logo"
              src="pixelated_riggeddice.png"
              rounded
            />
          </Row>
          <div className="riggeddice-mid-text">
            <h4 className="nes-text is-success">
              Push your imagination to the limits and join the amazing paper RPG
              world!
            </h4>
          </div>
          <Row>
            <Footer />
          </Row>
        </Row>
      </Row>
    </Container>
  );
};

LobbyPageComponent.propTypes = {
  openCreateSessionModal: PropTypes.func.isRequired,
  getSessionList: PropTypes.func.isRequired,
  joinBackToSession: PropTypes.func.isRequired,
  logoutFromSession: PropTypes.func.isRequired,
};
export default LobbyPageComponent;
