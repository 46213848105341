import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

const SessionPageComponent = ({
  bucketWidget,
  poolWidget,
  tableWidget,
  drawToken,
  resetSession,
  logoutFromSession,
  sessionName,
}) => (
  <Container className="riggeddice-container">
    <Row>
      <Col>
        <div className="nes-container is-rounded with-title is-dark is-centered top-menu">
          <p className="title">RIGGEDDICE</p>
          <Row>
            <button
              type="button"
              className="nes-btn logout-button"
              onClick={logoutFromSession}
            >
              Logout
            </button>
          </Row>
          <span className="col-md-10 session-name-header">{sessionName}</span>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="nes-container is-rounded with-title is-dark is-centered token-bucket-container">
          <p className="title">Bucket</p>
          <div className="token-list">{bucketWidget}</div>
        </div>
      </Col>
      <Col>
        <div className="nes-container is-rounded with-title is-dark is-centered token-pool-container">
          <p className="title">Pool</p>
          <div className="token-list">{poolWidget}</div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="nes-container is-rounded is-dark is-centered">
          <div className="nes-container with-title is-centered is-rounded is-dark token-table-container">
            <p className="title">Table</p>
            <div className="token-list table-widget">{tableWidget}</div>
          </div>
          <Container>
            <Row>
              <Col>
                <button
                  type="button"
                  className="nes-btn is-success col-md-6"
                  onClick={drawToken}
                >
                  Draw
                </button>
              </Col>
              <Col>
                <button
                  type="button"
                  className="nes-btn is-error col-md-6"
                  onClick={resetSession}
                >
                  Reset
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  </Container>
);

SessionPageComponent.propTypes = {
  bucketWidget: PropTypes.node.isRequired,
  poolWidget: PropTypes.node.isRequired,
  tableWidget: PropTypes.node.isRequired,
  drawToken: PropTypes.func.isRequired,
  resetSession: PropTypes.func.isRequired,
  logoutFromSession: PropTypes.func.isRequired,
  sessionName: PropTypes.string.isRequired,
};

export default SessionPageComponent;
