import React from 'react';

const Token = ({ rank, color, tokenClickHandler }) => (
  <span>
    <button
      type="button"
      style={{ backgroundColor: color }}
      className="nes-btn token-layout"
      onClick={() => tokenClickHandler({ rank, color })}
    >
      <span>{rank}</span>
    </button>
  </span>
);

export default Token;
