import uuid from 'uuid';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

import useStateWithLocalStorage from '../../useStateWithLocalStorage';

import SessionPageComponent from './SessionPage.component';
import BucketWidget from './BucketWidget.component';
import PoolWidget from './PoolWidget.component';
import TableWidget from './TableWidget.component';

const actionTypes = {
  GET_SESSION: 'GET_SESSION',
  ADD_TOKEN_TO_POOL: 'ADD_TOKEN_TO_POOL',
  REMOVE_TOKEN_FROM_POOL: 'REMOVE_TOKEN_FROM_POOL',
  DRAW_TOKENS: 'DRAW_TOKENS',
  RESET_SESSION: 'RESET_SESSION',
};

const prepareMessage = (action, payload) =>
  JSON.stringify({ action, ...payload });

const SessionPage = ({ sessionName }) => {
  const [pool, setPool] = useState([]);
  const [table, setTable] = useState([]);
  const [webSocketReady, setWebSocketReady] = useState([]);
  const ws = useRef(null);
  const history = useHistory();
  const { sessionId } = useParams();

  const [userId] = useStateWithLocalStorage('riggeddiceUserID');

  useEffect(() => {
    const protocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';

    const { host } = window.location;
    ws.current = new WebSocket(`${protocol}://${host}/ws?userId=${userId}`);

    ws.current.onopen = () => {
      setWebSocketReady(true);
      const message = prepareMessage(actionTypes.GET_SESSION, { sessionId });
      ws.current.send(message);
    };

    ws.current.onmessage = ({ data }) => {
      dispatchAction(data);
    };

    return () => ws.current.close();
  }, [userId, sessionId]);

  const dispatchAction = (rawData) => {
    const data = JSON.parse(rawData);
    if (data.action === actionTypes.GET_SESSION) {
      const { pool, table } = data.sessionData;
      setPool(pool);
      setTable(table);
    }
  };

  const sendMessage = (message) => {
    if (webSocketReady) {
      ws.current.send(message);
    }
  };

  const addTokenToPool = ({ rank, color }) => {
    const message = prepareMessage(actionTypes.ADD_TOKEN_TO_POOL, {
      sessionId,
      token: {
        id: uuid.v4(),
        rank,
        color,
      },
    });

    sendMessage(message);
  };

  const addTokenCollectionToPool = (tokensCollection) => {
    for (const { rank, color } of tokensCollection) {
      const message = prepareMessage(actionTypes.ADD_TOKEN_TO_POOL, {
        sessionId,
        token: {
          id: uuid.v4(),
          rank,
          color,
        },
      });
      sendMessage(message);
    }
  };

  const removeTokenFromPool = ({ id }) => {
    const message = prepareMessage(actionTypes.REMOVE_TOKEN_FROM_POOL, {
      sessionId,
      tokenId: id,
    });

    sendMessage(message);
  };

  const drawToken = () => {
    const message = prepareMessage(actionTypes.DRAW_TOKENS, {
      sessionId,
    });

    sendMessage(message);
  };

  const resetSession = () => {
    const message = prepareMessage(actionTypes.RESET_SESSION, {
      sessionId,
    });

    sendMessage(message);
  };

  const logoutFromSession = async () => {
    await axios.delete(
      `/api/sessions/${sessionId}/logout-user-from-session/${userId}`,
    );

    history.push('/');
  };

  return (
    <SessionPageComponent
      bucketWidget={
        <BucketWidget
          addTokenToPool={addTokenToPool}
          addTokenCollectionToPool={addTokenCollectionToPool}
        />
      }
      poolWidget={
        <PoolWidget pool={pool} removeTokenFromPool={removeTokenFromPool} />
      }
      tableWidget={<TableWidget table={table} />}
      drawToken={drawToken}
      resetSession={resetSession}
      sessionName={sessionName}
      logoutFromSession={logoutFromSession}
    />
  );
};

SessionPage.propTypes = {
  sessionName: PropTypes.string,
};

export default SessionPage;
