import React from 'react';
import PropTypes from 'prop-types';

const SessionList = ({ sessionList, sessionId, onSelectionChange }) => (
  <div className="nes-table-responsive riggeddice-session-list">
    {!sessionList.length ? (
      <div className="nes-container is-centered">
        <span className="nes-text is-error">There is no session available</span>
      </div>
    ) : (
      <table className="nes-table is-bordered is-centered">
        <thead>
          <tr>
            <th />
            <th style={{ width: '600px' }}>Name</th>
            <th style={{ width: '100px' }}>Type</th>
          </tr>
        </thead>
        <tbody>
          {sessionList.map((session) => (
            <tr key={session.sessionId}>
              <td>
                <label>
                  <button
                    style={{ top: '6px', right: '1px' }}
                    type="button"
                    className={
                      session.sessionId === sessionId
                        ? 'nes-btn btn-right is-primary'
                        : 'nes-btn btn-right'
                    }
                    onClick={() =>
                      onSelectionChange(
                        session.sessionId,
                        session.isPrivateSession,
                      )
                    }
                    value={sessionId}
                  ></button>
                  <span />
                </label>
              </td>
              <td style={{ textAlign: 'center' }}>{session.sessionName}</td>
              <td style={{ textAlign: 'center' }}>
                {session.isPrivateSession ? (
                  <span className="nes-text is-warning">Private</span>
                ) : (
                  <span className="nes-text is-success">Public</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

SessionList.propTypes = {
  sessionList: PropTypes.arrayOf(
    PropTypes.shape({
      sessionId: PropTypes.string.isRequired,
      sessionName: PropTypes.string.isRequired,
      isPrivateSession: PropTypes.bool.isRequired,
    }),
  ),
  selectedSessionId: PropTypes.string,
  onSelectionChange: PropTypes.func.isRequired,
};

export default SessionList;
