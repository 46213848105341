import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import SessionList from '../lobby/SessionList.component';

const JoinSessionModal = ({
  sessionList,
  showJoinSessionModal,
  closeJoinSessionModal,
  submitJoinSession,
}) => {
  const [sessionId, setSessionId] = useState(null);
  const [isPrivateSession, setIsPrivateSession] = useState(null);

  const resetForm = useCallback(() => {
    setSessionId(null);
  }, [setSessionId]);

  const handleSubmit = useCallback(() => {
    if (!sessionId) {
      return;
    }

    const payload = {
      sessionId,
      isPrivateSession,
    };

    resetForm();
    closeJoinSessionModal();
    submitJoinSession(payload);
  }, [
    resetForm,
    closeJoinSessionModal,
    submitJoinSession,
    isPrivateSession,
    sessionId,
  ]);

  const closeModal = useCallback(() => {
    resetForm();
    closeJoinSessionModal();
  }, [resetForm, closeJoinSessionModal]);

  const onSelectionChange = (sessionId, isPrivateSession) => {
    setSessionId(sessionId);
    setIsPrivateSession(isPrivateSession);
  };

  const joinButtonDisabled = !sessionList.length || !sessionId;

  return (
    <Modal show={showJoinSessionModal}>
      <div className="nes-dialog is-rounded riggeddice-dialog">
        Session List
        <menu className="dialog-menu">
          <SessionList
            sessionList={sessionList}
            sessionId={sessionId}
            onSelectionChange={onSelectionChange}
          />
          <button className="nes-btn is-error btn-left" onClick={closeModal}>
            Cancel
          </button>
          <button
            className={
              joinButtonDisabled
                ? 'nes-btn is-disabled btn-right'
                : 'nes-btn is-primary btn-right'
            }
            disabled={joinButtonDisabled}
            onClick={handleSubmit}
          >
            Ok
          </button>
        </menu>
      </div>
    </Modal>
  );
};

JoinSessionModal.propTypes = {
  sessionList: PropTypes.arrayOf(
    PropTypes.shape({
      sessionId: PropTypes.string.isRequired,
      sessionName: PropTypes.string.isRequired,
      isPrivateSession: PropTypes.bool.isRequired,
    }),
  ),
  showJoinSessionModal: PropTypes.bool.isRequired,
  closeJoinSessionModal: PropTypes.func.isRequired,
  submitJoinSession: PropTypes.func.isRequired,
};

JoinSessionModal.defaultProps = {
  sessionList: [],
};

export default JoinSessionModal;
