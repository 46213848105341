import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

import { Container, Row, Col } from 'react-bootstrap';

const emptySessionObject = {
  isPrivateSession: false,
  sessionId: '',
  sessioName: '',
};

const Passport = ({ children, userId }) => {
  const [password, setPassword] = useState('');
  const [userVerified, setUserVerified] = useState(false);
  const [sessionExist, setSessionExist] = useState(false);
  const [session, setSession] = useState(emptySessionObject);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { sessionId } = useParams();
  const history = useHistory();

  const { isPrivateSession, sessionName } = session;
  // check if session exists
  useEffect(() => {
    (async () => {
      setIsFetchingData(true);
      const {
        data: { sessionRecord },
      } = await axios(`/api/sessions/${sessionId}`);

      if (sessionRecord) {
        setSessionExist(true);
        setSession(sessionRecord);
      }

      return setIsFetchingData(false);
    })();
  }, [setSessionExist, setSession, sessionId]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/api/sessions/get-current-user-session/${userId}`,
      );

      if (data.sessionId) {
        return;
      }

      if (sessionName && !isPrivateSession) {
        await axios.post(`/api/sessions/${sessionId}/add-user-to-session`, {
          userId,
        });
      }
    })();
  }, [userId, sessionId, sessionName, isPrivateSession]);

  const verifyUser = async () => {
    const {
      data: { accessToSessionGranted },
    } = await axios.post(`/api/sessions/${sessionId}/verify-user`, {
      password,
    });

    if (accessToSessionGranted) {
      await axios.post(`/api/sessions/${sessionId}/add-user-to-session`, {
        userId,
      });
      return setUserVerified(true);
    }
    return setUserVerified(false);
  };

  const goBack = () => history.push('/');

  if (isFetchingData) {
    return (
      <Container className="riggeddice-container">
        <div className="nes-container is-dark is-rounded is-centered">
          <h1 className="nes-text is-warning">Loading Data...</h1>
        </div>
      </Container>
    );
  }

  if (!sessionExist) {
    return (
      <Container className="riggeddice-container">
        <div className="nes-container is-dark is-rounded is-centered">
          <h1 className="nes-text is-warning">Session does not exists! </h1>
          <br />
          <button type="button" className="nes-btn is-error" onClick={goBack}>
            Go Back
          </button>
        </div>
      </Container>
    );
  }

  if (sessionExist && !isPrivateSession) {
    return {
      ...children,
      props: { sessionName },
    };
  }

  return userVerified && sessionExist ? (
    {
      ...children,
      props: { sessionName },
    }
  ) : (
    <Container className="riggeddice-container">
      <div className="nes-container is-dark is-rounded is-centered">
        <h1 className="nes-text is-warning">This is a private session</h1>
        <p>To join please provide a valid password</p>
        <br />
        <div className="nes-field">
          <input
            onChange={({ target }) => setPassword(target.value)}
            className="nes-input is-dark"
            type="password"
          />
        </div>
        <br />
        <Row>
          <Col>
            <button
              type="button"
              className="nes-btn is-success"
              onClick={verifyUser}
            >
              Submit
            </button>
          </Col>
          <Col>
            <button type="button" className="nes-btn is-error" onClick={goBack}>
              Go Back
            </button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

Passport.propTypes = {
  children: PropTypes.node.isRequired,
  userId: PropTypes.string.isRequired,
  noPasswordRequried: PropTypes.bool,
};

Passport.defaultProps = {
  noPasswordRequried: false,
};

export default Passport;
