import uuid from 'uuid';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LobbyPage from './components/lobby/LobbyPage.container';
import SessionPage from './components/session/SessionPage.container';
import Passport from './components/Passport.container';
import useStateWithLocalStorage from './useStateWithLocalStorage';

import './App.css';
import './index.css';
import 'nes.css/css/nes.min.css';

const App = () => {
  const [userId, setUserId] = useStateWithLocalStorage('riggeddiceUserID');

  useEffect(() => {
    if (!userId) {
      setUserId(uuid.v4());
    }
  }, [userId, setUserId]);

  return (
    <Router>
      <Switch>
        <Route path="/session/:sessionId">
          <Passport userId={userId}>
            <SessionPage />
          </Passport>
        </Route>
        <Route path="/">
          <LobbyPage userId={userId} />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
