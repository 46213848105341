import React from 'react';
import PropTypes from 'prop-types';

import Token from './Token.component';

const TableWidget = ({ table }) => (
  <>
    {table.length
      ? table.map(({ id, rank, color }) => (
          <Token
            key={id}
            id={id}
            rank={rank}
            color={color}
            tokenClickHandler={() => ({})}
          />
        ))
      : null}
  </>
);

TableWidget.propTypes = {
  table: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      rank: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TableWidget;
